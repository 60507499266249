window.onpageshow = function(event) {
    if (event.persisted) {
        window.location.reload();
    }
};

jQuery(document).ready(function($){

    $('body').addClass('is-ready');


    $.easing.def = 'easeInOutQuart';

    var loaded = false;

    var st;
    var D;
    var dh;
    var fh;

    var has_scrolled = false;

    var document_title = document.title;
    // setTimeout(function(){
    //     $('body').addClass('is-done');
    // },700); 
    
    function redirectPage(link_location){ window.location = link_location; }

    $('a[target!="_blank"]').not('#wt-cli-settings-btn,#wt-cli-accept-btn,.cli-btn,.cli-nav-link,#cookie-law-info-bar a,.fp-play, .fp-mute, .regular, .scrolldown, [href^="mailto\\:"], [href$="\\#"]').on('click', function(e){

        if(e.ctrlKey === false && e.metaKey === false){

            console.log(e.ctrlKey);

            e.preventDefault();
            var link_location = this.href;
            
            /*
             * Woody stuff : begin / WIP
             */
            // var header_height = $('header.header').outerHeight();
    //        $('main.main').css('opacity',0);
            // $('header.header > div').css('opacity',0);
            // $('footer.footer').css('opacity',0);
    //        $('.progressbar').css('opacity',0);
    //        $('.header--nav ul li.current-menu-item a').css('border-bottom-color','rgba(25,25,25,0)');
    //        $('.single .header--nav ul li.current-menu-item a').css('border-bottom-color','rgba(255,255,255,0)');
            // $('header.header').css({
            //     'opacity': 0,
            //     'top': '-' + header_height + 'px',
            // });
            /*
             * Woody stuff : end
             */

            $('body').removeClass('is-ready');
            
            setTimeout(function(){
                redirectPage(link_location);
            },200); 
        }

    });

    $('.image-fadein img').on('load', function(){
        $('.image-fadein').addClass('is-loaded');
    });

    

    $('.scrolldown').on('click',function(e){
        e.preventDefault();
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(target).offset().top - $('.header').innerHeight() + 5
        }, 1200);
    });

    $('.hamburger').on('click',function(e){
        $('body').toggleClass('nav-is-open');
        if($('body').hasClass('nav-is-open')){
            $('.header--nav').fadeIn(450).addClass('is-open');
        }else{
            $('.header--nav').fadeOut(450).removeClass('is-open');
        }
    });

    function customDataEqualizer(){
        if(Foundation.MediaQuery.current !== 'small'){
            $('.cases-overview .row').each(function(){
                var $current_row = $(this);
                var block_heights = [];
                $current_row.find('.overviewblock-eq').each(function(){
                    var $current_block = $(this);
                    block_heights.push($current_block.height());
                });
                var largest_height = Math.max.apply(Math, block_heights);
                $current_row.find('.overviewblock-eq').css('height',largest_height+'px');
            });
        }
    }

    function casesEq(){
        if($('.archive .cases-overview').length){ customDataEqualizer(); }
        var $block_black = $('.home .blocks .columns').eq(0).find('.block-black');
        var h = $('.home .blocks .columns').eq(0).find('.block-grey').height() + $('.home .blocks .columns').eq(1).find('.block-black').height() + ($(window).width() * 0.0625);
        $block_black.css('height',h+'px');
        loaded = true;
        $('.cases-overview').addClass('is-eq');
        console.log('should work');
    }

    $(document).ready(function() {
        if(loaded === false){
            if(Foundation.MediaQuery.current === 'small' || Foundation.MediaQuery.current === 'medium'){
                $('.fh').each(function(){
                    $(this).css('height',$(window).height());
                });
                $('.fh-fold').each(function(){
                    $(this).css('height',($(window).height() - ($(window).width() * 0.0625)));
                });
                if(Foundation.MediaQuery.current === 'small'){
                    $('.fh-fold-medium-up').each(function(){
                        $(this).css('height',$(window).height());
                    });
                }else{
                    $('.fh-fold-medium-up').each(function(){
                        $(this).css('height',($(window).height() - ($(window).width() * 0.0625)));
                    });
                }
            }
            $(window).trigger('resize');
            casesEq();
        }
    });

    $(window).on('resize',function(){
        casesEq();
    });

    $('.cases-overview .overviewblock').each(function(){
        new Waypoint.Inview({
            element: this,
            enter: function(direction){
                if(direction === 'down'){
                    $('#'+this.element.id).addClass('is-in-view');
                }
            },
            // exited: function(direction){
            //     if(direction === 'down'){
            //         $('#'+this.element.id).addClass('is-out-view');
            //     }
            // },
            // enter: function(direction){
            //     if(direction === 'up'){
            //         $('#'+this.element.id).addClass('is-in-view');
            //         $('#'+this.element.id).removeClass('is-out-view');
            //     }
            // },
        });
    });
    
    if($('.slider').length){
    	
        $('.slider').each(function(){

            var $slider = $(this);
            $slider.slick({
                dots: false,
                infinite: false,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                cssEase: 'ease',
                draggable: true,
                centerMode: true,
                variableWidth: false,
                centerPadding: '6.25vw',
                arrows: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            infinite: false,
                            dots: false,
                            arrows: false,
                            draggable: true,
                            centerMode: true,
                            centerPadding: '6.25vw',
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            infinite: false,
                            dots: false,
                            arrows: false,
                            centerMode: true,
                            centerPadding: '3.12vw',
                        }
                    }
                ]
            });

            $slider.find('.js-slider-prev').click(function() {
                $slider.slick('slickGoTo', parseInt($slider.slick('slickCurrentSlide'))-1);
            });

            $slider.find('.js-slider-next').click(function() {
                $slider.slick('slickGoTo', parseInt($slider.slick('slickCurrentSlide'))+1);
            });
            
        });
    }
    
    if( $('section.studio').length && $('.slider').length && document.URL.indexOf('#') > 0){
    	var hash = document.URL.substr( document.URL.indexOf('#') + 1 );
    	var block_slide_regex = new RegExp('^block_([0-9])_slide_([0-9])$');

        if ( block_slide_regex.test( hash ) ) {
        	
        	var block_slide_location = hash.match(block_slide_regex);
        	var block = block_slide_location[1];
        	var slide = block_slide_location[2];
        	
        	$("html,body").animate({
    				scrollTop: $('#block_' + block).offset().top - 200
    			},"500"
			);
        	
        	$('#block_' + block).slick('slickGoTo', slide - 1);
        }
    }
    
    $(window).load(function(){

        if($('.progressbar').length){

            st = $(document).scrollTop();
            D = document;
            dh = Math.max(Math.max(D.body.scrollHeight, D.documentElement.scrollHeight), Math.max(D.body.offsetHeight, D.documentElement.offsetHeight), Math.max(D.body.clientHeight, D.documentElement.clientHeight));
            fh = dh - $(window).height();

            var progress = 0;

            $(document).on('scroll',function(){
                st = $(document).scrollTop();
                progress = st / (fh / 100);
                $('.progressbar').css('width',progress+'%');
            });

        }

    });
    
    $('.hovervideo--text a').each(function(){
    	var $video_link = $(this);
    	var target = $('#hovervideo--video');
    	
    	$video_link.on('click',function(e){
            if(Foundation.MediaQuery.current !== 'small'){
    		  e.preventDefault();
            }
		});
		
    	if( $('#hovervideo--video').length ){
    		
    		$video_link.on('mouseenter',function(){
    			
    			if(Foundation.MediaQuery.current !== 'small'){
    				var $trigger = $(this);
    		        
    		        if ($(target).get(0).paused) {
    		            $(target).get(0).play();
    		            $('body').addClass('hovervideo-is-playing');
    		        }
    			}
    	        
    	    }).on('mouseleave',function(){
    	    	
    	    	if(Foundation.MediaQuery.current !== 'small'){
    		        var $trigger = $(this);
    		        
    		        if ($(target).get(0).play) {
    		            $('body').removeClass('hovervideo-is-playing');
    	                $(target).get(0).pause();
    		        }
    	    	}
    	    });
    	}
    	else{
    		
    		$video_link.on('mouseenter',function(){
    			
    			if(Foundation.MediaQuery.current !== 'small'){
    				$('body').addClass('hovervideo-is-playing');
    			}
    	        
    	    }).on('mouseleave',function(){
    	    	
    	    	if(Foundation.MediaQuery.current !== 'small'){
    	    		$('body').removeClass('hovervideo-is-playing');
    	    	}
    	    });
    	}
    	
    	
    });
    
    $(document).on('scroll',function(){ has_scrolled = true; });

    $('.js-wp-home').each(function(){
        new Waypoint.Inview({
            element: this,
            enter: function(direction){
                if(direction === 'up' && Foundation.MediaQuery.current !== 'small'){
                    var client_name = $('#'+this.element.id).find('span.title').text();
                    $('.js-client-name').fadeOut(200);
                    setTimeout(function(){
                        $('.js-client-name').text(' ' + client_name  +'.');
                        $('.js-client-name').fadeIn('fast');
                    },200);
                }
            },
            entered: function(direction) {
                if(direction === 'down' && Foundation.MediaQuery.current !== 'small'){
                    var client_name = $('#'+this.element.id).find('span.title').text();
                    $('.js-client-name').fadeOut(200);
                    setTimeout(function(){
                        $('.js-client-name').text(' ' + client_name  +'.');
                        $('.js-client-name').fadeIn('fast');
                    },200);
                }
            }
        });
    });

    if( $('.js-wp-home').length ){
    	var $first_wp = $('.js-wp-home').get(0);
        var wp_length = $('.js-wp-home').length;
        var $last_wp = $('.js-wp-home').get(wp_length-1);

        var first_inview = new Waypoint.Inview({
            element: $first_wp,
            exit: function(direction){
                if(direction === 'up' && Foundation.MediaQuery.current !== 'small'){
                    $('.js-client-name').fadeOut(200);
                    setTimeout(function(){
                        $('.js-client-name').text('.');
                        $('.js-client-name').fadeIn('fast');
                    },200);
                }
            }
        });

        var last_inview = new Waypoint.Inview({
            element: $last_wp,
            exited: function(direction){
                if(direction === 'down' && Foundation.MediaQuery.current !== 'small'){
                    $('.js-client-name').fadeOut(200);
                    setTimeout(function(){
                        $('.js-client-name').text('.');
                        $('.js-client-name').fadeIn('fast');
                    },200);
                }
            }
        });
    }
    
    if( $('.case--video:not(.case--video-button) .flowplayer-video').length ){
    	
    	$('.case--video:not(.case--video-button) .flowplayer-video').each(function(index, element){
    		
    		$(this).find('.fp-message').html('');
    		
    		var player = flowplayer(index);
    		player.bind("ready", function(event, api) {
    		
    			player.disable(true);
    			player.mute(true);
    			player.pause();
	    		
	    		var flowplayer_waypoint = new Waypoint.Inview({
	                element: element,
	                enter: function(direction){
	                    player.play();
	                },
	                entered: function(direction){
	                    player.play();
	                },
	                exited: function(direction){
                		player.pause();
	                },
	            });
        	});
    	});
    	
    }
    
    if( $( 'section.case' ).length ){
    	
    	var $navigation = $('header.header').first();
    	var $offset_navigation = $navigation.first().outerHeight();
    	var $offset_header = $('section.case--header').first().outerHeight();
    	
    	$offset = ( 0 - $offset_header ) + $offset_navigation;
    	
    	$('section.case--header').first().waypoint( function( direction ) {
    			if( direction === 'down') {
    				$navigation.addClass('inverse');
    			}
    			else {
    				$navigation.removeClass('inverse');
    			}
			},{ 
				offset: $offset 
			}
		);
    	
    }

    if($('.arrow-js').length){
        $('.arrow-js').each(function(){
            var $self = $(this);
            if($(this).find('a').length){
                var $link = $(this).find('a');
                $link.append('<div class="arrow arrow-large"><div></div></div>');
            }
        });
    }

    // These titles are defined in the backend in all available languages.
    // See setup.php -> assets() function
	var document_blur_titles = js_variables.document_blur_titles;
    function randomNumberFromRange(min,max){
        return Math.floor(Math.random()*(max-min+1)+min);
    }

    $(window).on('blur',function(){ 
        if(!$('body').hasClass('single')){
            var index = randomNumberFromRange(1,document_blur_titles.length);
            document.title = document_blur_titles[index-1];
        }
    });

    $(window).on('focus',function(){ 
        document.title = document_title;
    });


    // form 

    $(window).on('load',function(){

        if($('form').length){

            $('form input, form textarea').on('focus',function(){
                var $self = $(this);
                if(!$self.hasClass('wpcf7-not-valid')){
                    // if(user_typing === false){
                        $self.closest('li').addClass('is-focus');
                        // user_typing = true;
                    // }
                }
            });
            $('form input').each(function(){
                var $self = $(this);
                if($self.val()){
                    $self.closest('li').addClass('is-focus');
                }
            });
            $('form textarea').each(function(){
                var $self = $(this);
                if($self.val()){
                    $self.closest('li').addClass('is-focus');
                }
            });

        }

    });

    var user_typing = false;

    if($('form').length){

        $('form input, form textarea').on('focus',function(){
            var $self = $(this);
            // if(user_typing === false){
                $self.closest('li').addClass('is-focus');
                // user_typing = true;
            // }
        });

        $('form input, form textarea').on('blur change',function(){
            var $self = $(this);
            if(!$self.val()){
                $(this).closest('li').removeClass('is-focus');
            }
            // user_typing = false;
        });
    }

});