jQuery(document).ready(function($){
	
	if( $( '.text-interchangeable-src' ).length ){
    	
		var index = 1;
		var textanimation_interval, words;
		var typingspeed = 80;
		var wait = 1000;
	
		function init(){
			words = [];
			
			$( '.text-interchangeable-src li' ).each(function(){
				words.push($(this).text());
			});
			
			setTimeout(function(){
				clearText();
			},wait);
		}
	
		function clearText(){
			$('.text-interchangeable-placeholder').html('');
			
			typeText(index);
			if(index < (words.length - 1)){
				index++;
			}else{
				index = 0;
			}
		}
	
		function typeText(data){
			animateText(words[data]);
		}
	
		function animateText(data){
			var goal = data;
			var textdata = data.split('');
			var textanimationindex = 0;
			var size = textdata.length - 1;
			
			textanimation_interval = setInterval(function(){
				var cur = $('.text-interchangeable-placeholder').html();
				var add = cur += textdata[textanimationindex];
				$('.text-interchangeable-placeholder').html(add);
				checkTextAnimationDone($('.text-interchangeable-placeholder').html(),goal);
				textanimationindex++;
			}, typingspeed);
			
		}
	
		function checkTextAnimationDone(data,goal){
			if(data === goal){
				clearInterval(textanimation_interval);
				setTimeout(function(){
					clearText();
				},wait);
			}
		}

		init();
		
	}

});